import { Info, Moon, Sun, SunHorizon } from '@phosphor-icons/react';
import { isToday, isTomorrow } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { getNextDayDate } from '@/core/services/getNextDayDate';
import { DeliveryScheduleSlotsQuery } from '@/modules/scheduler/queries/DeliveryScheduleSlots.delio.generated';

import { afternoonSlots, eveningSlots, morningSlots } from '../../consts';
import { DayButton } from '../DayButton/DayButton';
import { SlotsList } from '../SlotsList/SlotsList';

export const ReadOnlySlotsContent = ({
  deliverySlots,
}: {
  deliverySlots?: DeliveryScheduleSlotsQuery['deliveryScheduleSlots'];
}) => {
  const { t } = useTranslation('layout');
  const [selectedDay, setSelectedDay] = useState(new Date());

  const setToday = () => {
    setSelectedDay(new Date());
  };

  const setTomorrow = () => {
    const tomorrow = getNextDayDate(new Date().toISOString());
    setSelectedDay(tomorrow);
  };

  const isTodaySelected = isToday(selectedDay);
  const isTomorrowSelected = isTomorrow(selectedDay);

  const activeSlots = deliverySlots?.filter(({ dateFrom }) =>
    isTodaySelected
      ? isToday(new Date(dateFrom))
      : isTomorrow(new Date(dateFrom))
  );

  return (
    <div className="px-6 pb-3 pt-6">
      <h2 className="text-title-lg font-medium">
        {t('Available delivery times')}
      </h2>
      <p className="mt-3 flex items-center gap-1.5 text-sm text-text-subtle">
        <Info
          size={DEFAULT_ICON_SIZES.sm}
          weight="bold"
          className="text-text-subtle"
        />
        {t('You can choose your delivery time when placing your order')}
      </p>
      <div className="my-6 flex h-12 w-full rounded-full border border-border-subtle bg-surface-subtle p-1">
        <DayButton
          isActive={isTodaySelected}
          onClick={setToday}
          date={new Date()}
        />
        <DayButton
          isActive={isTomorrowSelected}
          onClick={setTomorrow}
          date={getNextDayDate(new Date().toISOString())}
        />
      </div>
      <div className="grid grid-cols-3 gap-6">
        <div>
          <SlotsList activeSlots={activeSlots} slots={morningSlots}>
            <Sun
              size={DEFAULT_ICON_SIZES.sm}
              weight="bold"
              className="text-text-subtle"
            />
            <p>{t('In the morning')}</p>
          </SlotsList>
        </div>
        <div>
          <SlotsList activeSlots={activeSlots} slots={afternoonSlots}>
            <SunHorizon
              size={DEFAULT_ICON_SIZES.sm}
              weight="bold"
              className="text-text-subtle"
            />
            <p>{t('In the afternoon')}</p>
          </SlotsList>
        </div>
        <div>
          <SlotsList activeSlots={activeSlots} slots={eveningSlots}>
            <Moon
              size={DEFAULT_ICON_SIZES.sm}
              weight="bold"
              className="text-text-subtle"
            />
            <p>{t('In the evening')}</p>
          </SlotsList>
        </div>
      </div>
    </div>
  );
};
