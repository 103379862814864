import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';
import { DeliveryTimePanelButton } from '@/modules/location/components/DeliveryTimePanelButton';
import { transformToRelativeDay } from '@/modules/scheduler/transformers/transformToRelativeDay';
import { Radio } from '@lib/theme/components/Radio';

export const DayButton = ({
  date,
  isSelected,
  onClick,
}: {
  date: Date;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const { i18n } = useTranslation();

  return (
    <DeliveryTimePanelButton
      isCheckout
      onClick={onClick}
      isSelected={isSelected}
    >
      <div>
        <div className={cn('capitalize', 'font-medium')}>
          {transformToRelativeDay({
            baseDate: new Date().toISOString(),
            targetDate: date,
            locale: i18n.language,
          })}
        </div>
        <div className={cn('text-xs')}>{formatDate(date, i18n.language)}</div>
      </div>
      <Radio isSelected={isSelected} />
    </DeliveryTimePanelButton>
  );
};

export const formatDate = (date: Date, locale: string) =>
  new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'long',
  }).format(date);
