import { ReactNode } from 'react';

import { cn } from '@/core/ui/utils';

export const DeliveryTimePanelButton = ({
  isSelected,
  children,
  onClick,
  isCheckout = false,
}: {
  isSelected: boolean;
  children: ReactNode;
  onClick: () => void;
  isCheckout?: boolean;
}) => (
  <button
    type="button"
    onClick={onClick}
    className={cn(
      'flex',
      'items-center',
      isSelected
        ? cn('border-primary-300', 'bg-primary-050')
        : cn('border-gray-300', 'bg-white'),
      'border',
      'rounded-xl',
      'px-3',
      'py-4',
      isCheckout ? ['md:py-6', 'md:px-5'] : ['py-2', 'px-4'],
      'grow',
      'text-left',
      'xs:text-sm',
      'xsm:text-base'
    )}
  >
    {children}
  </button>
);
