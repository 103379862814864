import * as Types from '@/types/gqlCommon.delio.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PromotionsNavQueryVariables = Types.Exact<{
  coordinates?: Types.InputMaybe<Types.CoordinatesInput>;
}>;


export type PromotionsNavQuery = { readonly __typename: 'Query', readonly webHomeFeedPromotionSection: { readonly __typename: 'WebHomeFeedPromotionSection', readonly section?: { readonly __typename: 'PromotionsSection', readonly id: string, readonly promotions: ReadonlyArray<{ readonly __typename: 'Promotion', readonly id: string, readonly key: string, readonly title: string, readonly iconUrl: string }> } | null } };


export const PromotionsNavDocument = gql`
    query PromotionsNav($coordinates: CoordinatesInput) {
  webHomeFeedPromotionSection(coordinates: $coordinates) {
    section {
      id
      promotions {
        id
        key
        title
        iconUrl
      }
    }
  }
}
    `;

/**
 * __usePromotionsNavQuery__
 *
 * To run a query within a React component, call `usePromotionsNavQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionsNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionsNavQuery({
 *   variables: {
 *      coordinates: // value for 'coordinates'
 *   },
 * });
 */
export function usePromotionsNavQuery(baseOptions?: Apollo.QueryHookOptions<PromotionsNavQuery, PromotionsNavQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromotionsNavQuery, PromotionsNavQueryVariables>(PromotionsNavDocument, options);
      }
export function usePromotionsNavLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionsNavQuery, PromotionsNavQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromotionsNavQuery, PromotionsNavQueryVariables>(PromotionsNavDocument, options);
        }
export function usePromotionsNavSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PromotionsNavQuery, PromotionsNavQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PromotionsNavQuery, PromotionsNavQueryVariables>(PromotionsNavDocument, options);
        }
export type PromotionsNavQueryHookResult = ReturnType<typeof usePromotionsNavQuery>;
export type PromotionsNavLazyQueryHookResult = ReturnType<typeof usePromotionsNavLazyQuery>;
export type PromotionsNavSuspenseQueryHookResult = ReturnType<typeof usePromotionsNavSuspenseQuery>;
export type PromotionsNavQueryResult = Apollo.QueryResult<PromotionsNavQuery, PromotionsNavQueryVariables>;