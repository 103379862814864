export const morningSlots = [
  { dateFrom: '7:00', dateTo: '8:00' },
  { dateFrom: '8:00', dateTo: '9:00' },
  { dateFrom: '9:00', dateTo: '10:00' },
  { dateFrom: '10:00', dateTo: '11:00' },
  { dateFrom: '11:00', dateTo: '12:00' },
];
export const afternoonSlots = [
  { dateFrom: '12:00', dateTo: '13:00' },
  { dateFrom: '13:00', dateTo: '14:00' },
  { dateFrom: '14:00', dateTo: '15:00' },
  { dateFrom: '15:00', dateTo: '16:00' },
  { dateFrom: '16:00', dateTo: '17:00' },
  { dateFrom: '17:00', dateTo: '18:00' },
];
export const eveningSlots = [
  { dateFrom: '18:00', dateTo: '19:00' },
  { dateFrom: '19:00', dateTo: '20:00' },
  { dateFrom: '20:00', dateTo: '21:00' },
  { dateFrom: '21:00', dateTo: '22:00' },
  { dateFrom: '22:00', dateTo: '23:00' },
  { dateFrom: '23:00', dateTo: '24:00' },
];

export const READ_ONLY_SLOTS_POPUP_OFFSET_TOP = 12;
