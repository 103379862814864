import { CaretDown } from '@phosphor-icons/react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { cn } from '@/core/ui/utils';

const MopedIcon = dynamic(async () => import('@/core/images/moped-icon.svg'), {
  ssr: false,
});

export const ClosestDeliveryInfo = ({
  isPopoverOpen,
  showCaretIcon,
  deliveryTime,
}: {
  isPopoverOpen?: boolean;
  showCaretIcon?: boolean;
  deliveryTime?: string;
}) => {
  const { t } = useTranslation('layout');

  const mainText = deliveryTime ? (
    `${t('Nearest delivery time')}:`
  ) : (
    <div className="flex items-center gap-2">
      <MopedIcon className={cn('h-4', 'w-4')} />
      {t('Shopping with delivery even on the same day')}
    </div>
  );

  return (
    <div className="flex gap-1 p-2 text-xs font-medium">
      <div className="text-text-subtle">{mainText}</div>
      <div className={cn('text-left', 'capitalize', 'flex', 'items-center')}>
        {deliveryTime}
        {showCaretIcon && (
          <CaretDown
            size={DEFAULT_ICON_SIZES.xs}
            weight="bold"
            className={cn(
              isPopoverOpen ? 'rotate-0' : 'rotate-180',
              'transition',
              '',
              'duration-500',
              'ease-in-out',
              'text-icon-default',
              'ml-0.5'
            )}
          />
        )}
      </div>
    </div>
  );
};
