import { cn } from '@/core/ui/utils';

export const Radio = ({ isSelected }: { isSelected: boolean }) => (
  <div
    className={cn(
      'w-4',
      'shrink-0',
      'ml-auto',
      'rounded-full',
      'h-4',
      isSelected
        ? cn(
            'shadow-[0px_0px_0px_4px_#25976D_inset]',
            'border',
            'border-primary-400'
          )
        : cn('border', 'border-gray-300')
    )}
  />
);
