import NextLink from 'next/link';
import { useTranslation } from 'next-i18next';

import { APP_ROUTES } from '@/config/routing';
import { cn } from '@/core/ui/utils';
import { PromotionsNavQuery } from '@/modules/layout/queries/PromotionsNav.delio.generated';

import JushLogo from '../images/jush-logo.svg';
import PromotionIcon from '../images/promotion-icon.svg';

type PromotionsSection = Extract<
  PromotionsNavQuery['webHomeFeedPromotionSection']['section'],
  { __typename: 'PromotionsSection' }
>;

export const PromotionsNav = ({
  promotions,
}: {
  promotions: PromotionsSection['promotions'];
}) => {
  const { t } = useTranslation('layout');

  if (!promotions) return null;

  return (
    <div className={cn('flex', 'gap-x-6', 'text-xs', 'font-medium')}>
      {promotions.map(({ id, key, iconUrl, title }, index) => (
        <NextLink
          key={id}
          href={APP_ROUTES.promotions(key)}
          shallow
          className={cn(
            index === 0
              ? ['text-apple-red', 'hover:text-[#B46052]']
              : ['text-gray-800', 'hover:text-primary-500'],
            'flex',
            'gap-x-1',
            'items-center'
          )}
        >
          {iconUrl ? (
            <div
              className={cn(
                'w-4',
                'h-4',
                'flex',
                'items-center',
                'justify-center'
              )}
            >
              <img src={iconUrl} alt="promotionIcon" />
            </div>
          ) : (
            <PromotionIcon
              className={cn(
                'w-4',
                'h-4',
                index === 0 ? 'text-apple-red' : 'text-primary-500'
              )}
            />
          )}
          {title}
        </NextLink>
      ))}
      <NextLink
        href="/zabka-jush"
        className="flex items-center gap-x-1.5 text-gray-800 hover:text-primary-500"
      >
        <JushLogo className="size-4" />
        <div className="text-body-sm font-medium">{t('Meet Zabka Jush')}</div>
      </NextLink>
    </div>
  );
};
