import { format, isBefore } from 'date-fns';
import { ReactNode } from 'react';

import { cn } from '@/core/ui/utils';

export const SlotsList = ({
  children,
  slots,
  activeSlots,
}: {
  children: ReactNode;
  slots: { dateFrom: string; dateTo: string }[];
  activeSlots?: readonly {
    dateFrom: string;
    dateTo: string;
    bookableUntil: string;
    available: boolean;
  }[];
}) => (
  <>
    <h2 className="flex items-center gap-2 border-b-2 border-border-subtle px-2.5 py-3 text-sm font-medium text-text-subtle">
      {children}
    </h2>
    <ul className="divide-y border-border-subtle">
      {slots.map(({ dateFrom, dateTo }) => {
        const isAvailable = activeSlots?.some(
          (el) =>
            el.available &&
            isBefore(new Date(), new Date(el.bookableUntil)) &&
            format(new Date(el.dateFrom), 'H:mm') === dateFrom &&
            format(new Date(el.dateTo), 'H:mm') === dateTo
        );
        return (
          <li
            key={dateFrom}
            className={cn(
              isAvailable ? 'text-text-default' : 'text-text-disabled',
              'px-2.5 py-3 text-sm font-medium'
            )}
          >
            {dateFrom}-{dateTo}
          </li>
        );
      })}
    </ul>
  </>
);
