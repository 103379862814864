import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { isBefore } from 'date-fns';

import { useDeliveryTime } from '@/core/hooks/useDeliveryTime';
import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';
import { useDeliveryScheduleSlotsQuery } from '@/modules/scheduler/queries/DeliveryScheduleSlots.delio.generated';
import { useStore } from '@/modules/store/hooks/useStore';
import { DelioBrandStatus } from '@/types/gqlCommon.common.generated';

import { ClosestDeliveryInfo } from './components/ClosestDeliveryInfo/ClosestDeliveryInfo';
import { ReadOnlySlotsContent } from './components/ReadOnlySlotsContent/ReadOnlySlotsContent';
import { READ_ONLY_SLOTS_POPUP_OFFSET_TOP } from './consts';

export const ReadOnlySlotsPopup = () => {
  const { store, loading: isStoreLoading } = useStore();
  const { coordinates, loading: isCoordinatesLoading } =
    useUserAddressCoordinates();
  const {
    loading: isSlotsLoading,
    data,
    refetch,
  } = useDeliveryScheduleSlotsQuery({
    variables: { coordinates },
    skip: !coordinates || isCoordinatesLoading,
    fetchPolicy: 'network-only',
  });

  const firstSlot = data?.deliveryScheduleSlots.find(
    ({ available, bookableUntil }) =>
      available && isBefore(new Date(), new Date(bookableUntil))
  );
  const deliveryTime = useDeliveryTime(store?.status, firstSlot, true);
  const isLoading = isSlotsLoading || isStoreLoading || isCoordinatesLoading;

  if (isLoading) return null;

  const refetchSlots = async () => {
    await refetch();
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          {store?.status === DelioBrandStatus.Available && firstSlot ? (
            <PopoverButton onClick={refetchSlots}>
              <ClosestDeliveryInfo
                isPopoverOpen={open}
                showCaretIcon
                deliveryTime={deliveryTime}
              />
            </PopoverButton>
          ) : (
            <ClosestDeliveryInfo deliveryTime={deliveryTime} />
          )}

          <PopoverPanel
            anchor={{
              to: 'bottom end',
              gap: READ_ONLY_SLOTS_POPUP_OFFSET_TOP,
            }}
            className="z-30 min-w-[552px] rounded-lg border border-border-subtle bg-white shadow-md [--anchor-gap:12px]"
          >
            <ReadOnlySlotsContent deliverySlots={data?.deliveryScheduleSlots} />
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};
