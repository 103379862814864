import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';
import { formatDate } from '@/modules/checkout/components/CheckoutScheduler/components/DayButton';
import { transformToRelativeDay } from '@/modules/scheduler/transformers/transformToRelativeDay';

export const DayButton = ({
  date,
  isActive,
  onClick,
}: {
  date: Date;
  isActive?: boolean;
  onClick: () => void;
}) => {
  const { i18n } = useTranslation();

  const day = `${transformToRelativeDay({
    baseDate: new Date().toISOString(),
    targetDate: date,
    locale: i18n.language,
  })}: `;

  return (
    <button
      onClick={onClick}
      type="button"
      className={cn(
        'text-sm',
        'font-medium',
        'p-2.5',
        'rounded-full',
        'flex-1',
        'text-center',
        'whitespace-pre',
        'transition-all',
        'duration-200',
        'hover:bg-action-secondary-default',
        'focus:ring-2',
        'focus:ring-action-primary-focused',
        'active:bg-action-tertiary-hovered',
        isActive ? 'bg-white shadow-md' : 'bg-transparent'
      )}
    >
      <span className="flex items-center justify-center">
        <span className="first-letter:capitalize">{day}</span>
        <span className="text-text-subtle">
          {formatDate(date, i18n.language)}
        </span>
      </span>
    </button>
  );
};
